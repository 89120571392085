/*
 * @Author: your name
 * @Date: 2021-11-11 15:46:38
 * @LastEditTime: 2024-11-26 13:06:07
 * @LastEditors: XuHongTao
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\main.ts
 */
import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import App from './app.vue';
import { checkIfLogin } from './utils';
import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/login/login.vue';
import Register from './views/register/register.vue';
// AuthService.checkIfLogin();

(async () => {
    if (!(await checkIfLogin())) {
        const app = createApp(App);

        const routes = [
            { path: '/', component: Login },
            { path: '/register', component: Register },
        ];

        const router = createRouter({
            history: createWebHistory(),
            routes,
        });
        app.use(router);

        app.use(Antd).mount('#app');
    }
})();
