<!--
 * @Author: your name
 * @Date: 2021-08-23 10:19:46
 * @LastEditTime: 2024-12-10 15:19:33
 * @LastEditors: XuHongTao
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\views\login\login.vue
-->
<style lang="less" src="./login.less"></style>
<template>
  <div class="base-container" :class="desktop ? 'desktop' : ''">
    <Tryout :visible="tryouotVisible" @close="tryouotVisible = false" />
    <section class="base-wrapper">
      <section class="common-wrapper">
        <section v-if="!forgetFlag" class="content-common-width login-content">
          <div :class="isCommunity ? 'left-bg-community' : 'left-bg'"></div>
          <div class="login-main">
            <div v-if="!logined">
              <h1>欢迎登录<img src="@/assets/images/circle.png" alt="" /></h1>
              <div class="tabs">
                <div
                  class="tab-item"
                  v-for="(item, index) in tabs"
                  :key="index"
                  :class="index == curIndex ? 'cur' : ''"
                  @click="_changeIndex(index)"
                >
                  {{ item }}
                </div>
              </div>
              <a-form
                v-show="curIndex === 0"
                name="custom-validation"
                class="login-form"
                :rules="rules"
                :model="loginForm"
                ref="formRef"
              >
                <a-form-item class="form-item" has-feedback name="phone">
                  <a-input
                    id="phone"
                    placeholder="请输入手机号"
                    v-model:value="loginForm.phone"
                  />
                  <!-- <a-input placeholder="用户名" v-model:value="loginForm.account"></a-input> -->
                </a-form-item>
                <a-form-item class="form-item" name="password">
                  <a-input-password
                    id="password"
                    :maxlength="20"
                    type="password"
                    placeholder="请输入密码"
                    v-model:value="loginForm.password"
                  ></a-input-password>
                </a-form-item>
                <a-form-item class="form-item code" name="code">
                  <a-input
                    id="code"
                    placeholder="请输入验证码"
                    v-model:value="loginForm.code"
                    autocomplete="off"
                  ></a-input>
                  <img ref="code" :src="codeImg" @click="_setCodeurl" alt="" />
                </a-form-item>
                <div class="forget-pwd-box">
                  <span @click="forgetFlag = true">忘记密码</span>
                </div>
                <!-- <div class="checkbox-item">
                                    <span @click="_remberHandel()">
                                        <i :class="[loginForm.loginFree ? 'icon-xuanze' : 'icon-checkbox1', 'iconfont']"></i>
                                        7日免登录
                                    </span>
                                </div> -->
                <div class="button-wrap">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :disabled="disabledLogin"
                    @click="_login"
                    >登录</a-button
                  >
                </div>
              </a-form>
              <a-form
                v-show="curIndex === 1"
                name="custom-validation"
                class="login-form"
                :rules="rules"
                :model="loginFormPhone"
                ref="formPhoneRef"
              >
                <a-form-item has-feedback name="phone" ref="phone" class="form-item">
                  <a-input
                    id="phone"
                    placeholder="请输入手机号"
                    v-model:value="loginFormPhone.phone"
                  />
                </a-form-item>
                <a-form-item name="code" class="form-item code">
                  <a-input
                    id="code"
                    placeholder="请输入短信验证码"
                    v-model:value="loginFormPhone.code"
                  />
                  <a-button @click="_getCode" :disabled="disabled">{{ btnTxt }}</a-button>
                </a-form-item>
                <div class="forget-pwd-box">
                  <span @click="forgetFlag = true">忘记密码</span>
                </div>
                <div class="button-wrap">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :disabled="disabledLogin"
                    @click="_login"
                    >登录</a-button
                  >
                </div>
              </a-form>
            </div>
            <!-- <div class="user-login-other">
                            <a @click="onThirdLogin('dingtalk')" title="钉钉"><DingdingOutlined /></a>
                        </div> -->
            <div v-if="isCommunity" class="application-exp">
              还没有账号？试用社区版
              <RouterLink
                :to="{ path: '/register', query: { returnUrl, isCommunity: '1' } }"
                >立即注册</RouterLink
              >
            </div>
            <div v-else class="application-exp">
              还没有账号？立即
              <a :href="trialUrl" target="_self"> 申请使用</a>
            </div>
            <div class="company" v-if="logined && isHaveMore">
              <!-- 所在多家公司 -->
              <h1>
                <span>选择你的组织</span>
                <span @click="_chooseCompany" class="iconfont icon-jinru-copy"></span>
              </h1>
              <span class="join">您已加入以下组织</span>
              <ul>
                <li
                  class="company-list"
                  :class="curCompanyIndex === index ? 'active' : ''"
                  v-for="(item, index) in companys"
                  @click="curCompanyIndex = index"
                  @dblclick="_chooseCompany"
                  :key="item.id"
                >
                  <div>
                    <span class="iconfont icon-qiye-copy"></span>
                  </div>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </div>
            <div v-if="logined && isFirst && !isHaveMore">
              <!-- 是否第一次登录 -->
              <h1>
                欢迎登录
                <img src="@/assets/images/circle.png" alt="" />
              </h1>
              <p class="edit-pwd">首次登录,请先设置密码</p>
              <a-form
                name="custom-validation"
                class="login-form"
                :rules="rules"
                :model="loginForm"
                ref="formEditRef"
              >
                <a-form-item class="form-item" name="newPassword">
                  <!-- <label for="newPassword">设置密码</label> -->
                  <a-input
                    id="newPassword"
                    type="password"
                    :maxlength="20"
                    placeholder="设置密码"
                    v-model:value="loginForm.newPassword"
                  ></a-input>
                </a-form-item>
                <a-form-item class="form-item" name="surePassword">
                  <a-input
                    id="surePassword"
                    type="password"
                    :maxlength="20"
                    placeholder="确认密码"
                    v-model:value="loginForm.surePassword"
                  ></a-input>
                </a-form-item>
                <div class="button-wrap">
                  <a-button
                    type="primary"
                    :disabled="disabledLogin"
                    @click="_enterPlatform"
                    >进入平台</a-button
                  >
                </div>
              </a-form>
            </div>
            <!-- <div class="bottom">
                            <div class="bottom_other">
                                <p class="sign">
                                    没有账号?
                                    <router-link to="">注册账号</router-link>
                                </p>
                                <div class="forget-password">忘记密码？</div>
                            </div>
                            <div class="bottom-tip">
                                <a-divider type="horizontal">使用合作方账号登录(须绑定手机号)</a-divider>
                            </div>
                        </div> -->
          </div>
        </section>
        <ForgetPwd @back="forgetFlag = false" v-else />
      </section>
    </section>
    <footer>
      <section class="content-common-width login-footer">
        <div>
          <span>版权声明</span>|<span>联系我们</span>|<span
            >Copyright © 2022 design order 沪ICP备2020035517号</span
          >
        </div>
      </section>
    </footer>
  </div>
</template>

<script lang="ts" src="./login.ts"></script>
