<style lang="less" src="./app.less"></style>
<template>
  <header>
    <section class="content-common-width">
      <div class="logo-wrapper">
        <a :href="officialUrl" target="_blank">
          <img src="./assets/images/logo.png" width="143" height="39" />
        </a>
      </div>
    </section>
  </header>
  <main>
    <RouterView />
  </main>
</template>

<script setup lang="ts">
import { ref } from "vue";
const officialUrl = ref(window.ENV_CONFIG.officialUrl);
</script>
