/*
 * @Author: your name
 * @Date: 2021-11-12 15:41:48
 * @LastEditTime: 2024-11-25 16:55:30
 * @LastEditors: XuHongTao
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\env.ts
 */
class EnvConfigImpl {
    public getBaseUrl(): string {
        return window.ENV_CONFIG.baseUrl;
    }
    public getBaseUrl2(): string {
        return window.ENV_CONFIG.baseUrl2;
    }

    public getDomianURL(): string {
        return window.ENV_CONFIG.domianURL;
    }

    public getHomeUrl(): string {
        return window.ENV_CONFIG.homePageUrl;
    }
    public getHomeUrl2(): string {
        return window.ENV_CONFIG.home2PageUrl;
    }
    public getEnv() {
        return window.ENV_CONFIG.env;
    }

    public getOfficialUrl(): string {
        return window.ENV_CONFIG.officialUrl;
    }
}

export const EnvConfig = new EnvConfigImpl();
