<template>
  <div class="register-container">
    <div class="content">
      <div class="banner">
        <div class="title">
          <div class="main-title">欢迎来到</div>
          <div class="sub-title">进入闪设，开启智能设计</div>
        </div>
        <div class="banner-app-name">闪设</div>
        <img src="../../assets/images/trial_modal_pic.png" alt="" />
      </div>

      <a-form
        ref="formRef"
        :model="formData"
        name="sb"
        @finish="submitForm"
        layout="vertical"
        class="register-form"
      >
        <a-form-item
          label="用户名"
          name="userName"
          :rules="[
            { required: true, message: '请输入10位以内字符', pattern: '^.{0,10}$' },
          ]"
        >
          <a-input v-model:value="formData.userName" placeholder="请输入用户名" />
        </a-form-item>

        <a-form-item
          label="手机号"
          name="phone"
          :rules="[
            {
              required: true,
              message: '请输入正确的手机号',
              pattern: /^1[3456789]\d{9}$/,
            },
          ]"
        >
          <a-input v-model:value="formData.phone" placeholder="请输入手机号" />
        </a-form-item>

        <!--   <a-form-item
          label="邮箱"
          name="email"
          :rules="[{ required: true, message: '请输入正确的邮箱', type: 'email' }]"
        >
          <a-input v-model:value="formData.email" placeholder="请输入邮箱" />
        </a-form-item> -->

        <a-form-item
          label="验证码"
          name="captcha"
          class="form-item code"
          :rules="[{ required: true, message: '请输入验证码' }]"
        >
          <a-input
            id="code"
            placeholder="请输入短信验证码"
            v-model:value="formData.captcha"
          />
          <a-button @click="_getCode" :disabled="disabled">{{ btnTxt }}</a-button>
        </a-form-item>

        <a-form-item
          label="公司名称"
          name="tenantAlias"
          :rules="[{ required: true, message: '请输入公司名称' }]"
        >
          <a-input v-model:value="formData.tenantAlias" placeholder="请输入公司名称" />
        </a-form-item>

        <a-form-item label="所在行业" name="industry">
          <a-radio-group
            v-model:value="formData.industry"
            style="display: flex; justify-content: space-between"
          >
            <a-radio-button
              v-for="(item, index) in industries"
              :key="index"
              :value="item"
              >{{ item.name }}</a-radio-button
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item style="text-align: center; margin-top: 68px">
          <a-button class="register-btn" type="primary" html-type="submit"
            >立即注册</a-button
          >
        </a-form-item>
        <div class="bottom-text">
          已注册账号
          <RouterLink :to="{ path: '/', query: { ...route.query } }">立即登录</RouterLink>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { EnvConfig } from "@/env";
import { message } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { JeecgAuthService } from "@do/do-auth-web-client";
import axios from "axios";
import { AuthService } from "../../service/auth_service";
interface IFormData {
  userName: string;
  phone: string;
  email: string;
  tenantAlias: string;
  captcha: string;
  industry: {
    id: string;
    childId: string;
    name: string;
  };
}

const formData = reactive<IFormData>({
  userName: "",
  phone: "",
  email: "",
  tenantAlias: "",
  captcha: "",
  industry: {
    id: "",
    childId: "",
    name: "",
  },
});
const formRef = ref();
const route = useRoute();
const router = useRouter();
const btnTxt = ref("获取验证码");
const disabled = ref(false);
const time = ref(60);

const returnUrlRef = ref("");
const bVisible = ref(true);
const intervalId = ref();
const industries = ref<{ id: string; name: string; childId: string }[]>([]);
onMounted(async () => {
  fetch(`${EnvConfig.getBaseUrl()}/doMistServer/communityUser/findByCondition`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pid: "1250472583391621120",
      includeAllChildren: true,
      auth: 1,
      deep: 5,
      sortVo: { orderBy: "sort", sort: "asc" },
    }),
  }).then((res) => {
    if (res.status === 200) {
      res.json().then((data) => {
        industries.value = data.result.map((item: any) => ({
          id: item.id,
          childId: item.children?.[0].id,
          name: item.name,
        }));
        formData.industry = industries.value[0];
      });
    }
  });
});

const _getCode = async (): Promise<void> => {
  await (formRef.value as ANY).validate("phone");
  const { phone } = formData;
  _countDown();
  const key = "updatable";
  message.loading({ content: "短信发送中", key, duration: 0 });
  try {
    const data = await JeecgAuthService.sms({
      mobile: phone,
      smsmode: "3",
    });

    message.success({ content: "验证码已发送", key });
  } catch (ex: any) {
    message.error({ content: `短信发送失败: ${ex.message}`, key });
    clearInterval(intervalId.value);
    btnTxt.value = "获取验证码";
    disabled.value = false;
  } finally {
  }
};

const _countDown = () => {
  btnTxt.value = `${time.value}s`;
  disabled.value = true;
  intervalId.value = setInterval(() => {
    time.value--;
    btnTxt.value = `${time.value}s`;
    if (time.value <= 0) {
      clearInterval(intervalId.value);
      btnTxt.value = "获取验证码";
      disabled.value = false;
      if (time.value === 0) {
        time.value = 60;
      }
    } else {
      disabled.value = true;
    }
  }, 1000);
};

const submitForm = (values: IFormData) => {
  axios
    .post(
      `${EnvConfig.getBaseUrl()}/v3/sys/user/addCommunity`,
      {
        ...values,
        industry: values.industry.id,
        industryName: values.industry.name,
        workscene: values.industry.childId,
        userStatus: 4,
        selectedroles: "1810194602511429634",
        realname: values.userName,
        extendStatus: 1,
        doGold: 100,
        userName: values.phone,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(async ({ data }) => {
      if (data?.code === 200) {
        axios
          .get(`${EnvConfig.getBaseUrl()}/doMistServer/communityUser/initUserCommunity`, {
            params: {
              username: values.phone,
            },
          })
          .then(async ({ data }) => {
            if (data.code === 200) {
              console.log("社区版注册");
              message.success("账号注册成功，即将跳转登录页面");
              const returnUrl = encodeURIComponent(
                `${EnvConfig.getHomeUrl2()}/shanshe-community/index?tenantId=0&app=shanshe-community`
              );
              router.push({ path: "/", query: { returnUrl, isCommunity: "1" } });
            } else {
              message.error(data.message || data.msg);
            }
          })
          .catch((error) => {
            // 处理错误
            console.error(error);
            message.error("请求失败，请重试");
          });
        if (EnvConfig.getEnv() === "production") {
          await AuthService.setUserInCrm({
            industry: values.industry.name,
            userName: values.userName,
            phone: values.phone,
            customerName: values.tenantAlias,
            source: "社区版注册",
            allocated: false,
          });
        }
      } else {
        message.error(data.message || data.msg);
      }
    });
};

const exitUse = () => {
  // Implement exit logic here
};
</script>

<style lang="less" src="./register.less"></style>
